import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Customerjourney from "../modules/SubFetaures/Customerjourney"

const customerjourney = () => (
  <Layout>
    <SEO
      title="Customer Journey"
      description="Consumer decision making process is a complex process that involves identifying needs to finally concluding their buying decision.
    As you might want to employ different business approach for different regions/ industry sectors, 
    with Korero you have the flexibility to setup different processes for different regions/ industry sectors."
    />
    <Customerjourney />
    <script src="https://platform.linkedin.com/in.js" type="text/javascript">
      {" "}
      lang: en_US
    </script>
  </Layout>
)

export default customerjourney
